<template>
  <div class="container mb-5">
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-center px-3">
        <div class="col-md-6">
          <h2>Bank Soalan</h2>
        </div>
      </div>
    </div>
    <div class="card-lists">
      <div class="row mx-0">
        <div
          class="col col-md-3 col-sm-6 "
          v-for="course in course"
          :key="course.id"
        >
          <div class="card mb-4" @click="goto('/admin/questions/' + course.id)">
            <div class="card-body position-relative">
              <span class="position-abolute top-0 float-end">
                <i class="fas fa-cog"></i>
              </span>
              <div class="position-absolute bottom-0 mb-2">
                <h5 class="card-title">{{ course.attributes.course_name }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">
                  Jumlah Soalan:
                  {{ course.attributes.question_banks.data.length }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../utils/API";
import $ from "jquery";

export default {
  data() {
    return {
      course: []
    };
  },
  methods: {
    goto(_path) {
      this.$router.push(_path);
    },
    async fetchData() {
      const res = await API.get("courses?populate[0]=question_banks&filters[course_type][$eq]=online");
      this.course = res.data.data;
    }
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";
</style>